.blog-1 .card-blog {
    margin-bottom: 80px;
}

.card.card-blog {
    margin-top: 5px;
}

.card.card-blog, .card.card-testimonial .card-description + .card-title {
    margin-top: 30px;
}
.card.card-plain {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
}
.card-plain {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
}
.card {
    border-radius: 12px;
    box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
    background-color: #FFFFFF;
    color: #333333;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    border: 0 none;
    transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.card.card-blog [class*=col-] .card-image .img {
    border-radius: 12px;
}

.card.card-blog .card-image .img, .card.card-testimonial .card-description + .card-title .card-image .img {
    border-radius: 12px;
}
.card.card-plain img {
    border-radius: 12px;
}
.card-plain img {
    border-radius: 12px;
}
.card img {
    max-width: 100%;
    height: auto;
    border-radius: 12px 12px 0 0;
}

img {
    vertical-align: middle;
    border-style: none;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.card .card-description {
    color: #9A9A9A;
    margin-bottom: 5px;
    margin-top: 15px;
}

p {
    font-size: 15px;
    line-height: 1.5em;
    margin-bottom: 5px;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, a, .td-name, td, button, input, select, textarea {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "Montserrat", "Helvetica", Arial, sans-serif;
    font-weight: 300;
}

title, .title a, .card-title, .card-title a, .info-title, .info-title a, .footer-brand, .footer-brand a, .footer-big h5, .footer-big h5 a, .footer-big h4, .footer-big h4 a, .media .media-heading, .media .media-heading a {
    color: #333333;
    text-decoration: none;
}
.card.card-blog .card-category {
    padding-top: 10px;
}

.card .card-category {
    color: #9A9A9A;
}
.card h6 {
    font-size: 12px;
    margin: 0;
}
.text-info, .text-info:hover {
    color: #51bcda !important;
}

.card.card-plain .card-body {
    padding-left: 5px;
    padding-right: 5px;
}

.card-plain .card-body {
    padding-left: 5px;
    padding-right: 5px;
}
.card .card-body {
    padding: 20px;
}
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

@media (min-width: 768px) {
.col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}
}

@media (min-width: 768px){
.col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}}

.title {
    margin-top: 30px;
    margin-bottom: 25px;
    min-height: 32px;
}

.title, .title a, .card-title, .card-title a, .info-title, .info-title a, .footer-brand, .footer-brand a, .footer-big h5, .footer-big h5 a, .footer-big h4, .footer-big h4 a, .media .media-heading, .media .media-heading a {
    color: #333333;
    text-decoration: none;
}